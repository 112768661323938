import QueryParameters from 'helpers/query-parameters.js';
import { ExceptionService } from '@busuu/legacy-core';
// Constants
import { ANDROID_IDENTIFIER, IOS_IDENTIFIER } from 'mobile/mobile-constants';
const FILENAME = 'mobile-service.ts';
/**
 * Logs and Triggers an error.
 * @param {String} type
 * @param {String} msg
 * @private
 */
const throwError = (msg, type) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    ExceptionService.handle('error', {
        filename: FILENAME,
        message: msg,
        data: {},
        sendToSentry: true,
    });
    throw {
        type,
        message: msg,
    };
};
/**
 * Indicates whether or not the user is using
 * authentication or registration via the mobile flow.
 * Matches against ?auth-type=ios|android
 * Documentation: https://busuucom.atlassian.net/wiki/spaces/FRON/pages/5238849622/Login+via+Web+from+Mobile+Front-end
 * @return boolean
 */
const isAuthenticationOrRegistrationMobileFlow = () => {
    const authType = QueryParameters.getMobileAuthParameter();
    return [ANDROID_IDENTIFIER, IOS_IDENTIFIER].includes(authType);
};
/**
 * Invokes a given Android Namespace callback.
 * The Android namespace is defined by the Android app
 * when a web-view is launched. It's defined onto the window.
 * @param {AndroidMethodTypes} callbackName - function name
 * @param {Object} params - callback arguments, optional
 * @public
 */
const invokeAndroidNamespace = (callbackName, params) => {
    const namespace = window.Android;
    if (!namespace) {
        return throwError('invokeAndroidNamespace() Android Namespace is missing', 'MOBILE_ANDROID_NAMESPACE_MISSING');
    }
    try {
        const androidParams = JSON.stringify(params);
        namespace[callbackName](androidParams);
    }
    catch (e) {
        throwError(e, 'MOBILE_ANDROID_CALLBACK_FAILURE');
    }
};
/**
 * @param {MobileCallbackParametersInterface} params
 * @returns {String}
 * @private
 */
const getiOSParams = (params) => {
    let str = '';
    if (params.nonce) {
        str += `nonce=${params.nonce}`;
    }
    else if (params.backendRedirection) {
        str += `location=${window.encodeURIComponent(params.backendRedirection)}`;
    }
    str += `&access_type=${params.accessType}&source=${params.source}`;
    return str;
};
/**
 * @param {MobileCallbackParametersInterface} params
 * @returns {Object}
 * @private
 */
const getAndroidParams = (params) => {
    return Object.assign(Object.assign(Object.assign({}, (params.nonce && {
        nonce: params.nonce,
    })), (params.backendRedirection && {
        location: params.backendRedirection,
    })), { access_type: params.accessType, source: params.source });
};
/**
 * @param {MobileCallbackParametersInterface} params
 * @returns {AndroidMethodTypes}
 * @private
 */
const getAndroidCallbackType = (params) => {
    if (params.nonce) {
        return 'onNonceAvailable';
    }
    else if (params.backendRedirection) {
        return 'onLocationAvailable';
    }
    throwError('getAndroidCallbackType() callback not found.', 'MOBILE_ANDROID_CALLBACK_NOT_FOUND');
};
/**
 * Function used in the Mobile authentication flow.
 * After the authentication or registration is success, we invoke a Mobile callback to
 * trigger the app to pass back the nonce token or a given location.
 * Documentation: https://busuucom.atlassian.net/wiki/spaces/FRON/pages/5238849622/Login+via+Web+from+Mobile+Front-end
 * @param {MobileCallbackParametersInterface} params
 * @public
 */
const callbackMobileAppAfterAuthenticationOrRegistration = (params) => {
    if (Boolean(!params.nonce && !params.backendRedirection) || !params.source) {
        throwError('callbackMobileAppAfterAuthenticationOrRegistration() Mandatory param is missing.', 'MOBILE_PARAMS_MISSING');
    }
    const authType = QueryParameters.getMobileAuthParameter();
    const androidParams = getAndroidParams(params);
    const androidCallbackType = getAndroidCallbackType(params);
    switch (authType) {
        case IOS_IDENTIFIER:
            window.location.href = `busuuauth://auth?${getiOSParams(params)}`;
            break;
        case ANDROID_IDENTIFIER:
            invokeAndroidNamespace(androidCallbackType, androidParams);
            break;
        default:
            throwError('callbackMobileAppAfterAuthenticationOrRegistration() authType not found.', 'MOBILE_AUTH_TYPE_MISSING');
    }
};
/**
 * Checks if the string passed in via the visitor_id query param is a valid UUID
 * Will not match NIL UUID
 */
const visitorIdIsUUID = (visitorId) => {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regex.test(visitorId);
};
const MobileService = {
    isAuthenticationOrRegistrationMobileFlow,
    callbackMobileAppAfterAuthenticationOrRegistration,
    invokeAndroidNamespace,
    visitorIdIsUUID,
};
export default MobileService;
